import { getScreenSizeType } from './util';

const VideoComponent = (() => {
    const isMobile = getScreenSizeType().indexOf('mobile-pt') > -1;
    const videoPlayer = document.querySelector('.js-video-player'); 

    /**
     * @description Function for adding desktop and mobile URLs
     */
    function addVideoSrc() {
        if (isMobile && videoPlayer.getAttribute('src-mobile') !== null) {
            videoPlayer.setAttribute('src', videoPlayer.getAttribute('src-mobile'));
        } else {
            videoPlayer.setAttribute('src', videoPlayer.getAttribute('src-desktop'));
        }
    }

    return {
        init() {
            addVideoSrc();
        },
        resize() {
            addVideoSrc();
        }
    }
})();

export default (() => {
    document.addEventListener("DOMContentLoaded", () => {
        VideoComponent.init();
    });

    window.addEventListener('resize', () => {
        VideoComponent.resize();
    });
})();
